import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { CaretIconDown } from 'src/assets/icons';
import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';

const Title = styled('button')`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  column-gap: 28px;
  display: flex;
  font-size: 16px;
  font-family: inherit;
  padding: 18px 0;
  text-align: left;
  width: 100%;

  @media ${device.mobileDOWN} {
    font-weight: 700;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 0;
  }
`;

const Content = styled('p')`
  font-size: 14px;
  margin: 0 0 24px;

  @media ${device.tabletUP} {
    padding-left: 38px;
  }
`;

const StyledCaretIcon = styled(CaretIconDown)<{ isExpanded?: boolean }>`
  min-width: 10px;
  transition: transform 150ms linear;
  width: 10px;

  & path {
    stroke: ${colors.black};
    stroke-width: 4;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: scaleY(-1);
    `}
`;

interface ExpandableTextProps {
  title: string;
  children: string;
}

const ExpandableText: FC<ExpandableTextProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Title onClick={() => setIsExpanded(!isExpanded)}>
        <StyledCaretIcon isExpanded={isExpanded} />
        {title}
      </Title>
      {isExpanded && <Content>{children}</Content>}
    </>
  );
};

export default ExpandableText;
