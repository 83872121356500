import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import Heading, { HeadingVariant } from 'src/components/Heading';
import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';

const Wrapper = styled.div<{ $centered: boolean }>`
  max-width: 1000px;
  padding: 80px 0 40px;
  text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};

  @media ${device.tabletDOWN} {
    padding: 48px 0 24px;
  }

  ${({ $centered }) =>
    $centered &&
    css`
      margin: 0 auto;
    `}
`;

const Subtitle = styled.p`
  color: ${colors.green400};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 12px;
  text-transform: uppercase;
`;

const IntroText = styled.p`
  font-size: 16px;
  margin: 16px 0 0;
`;

interface SectionTitleProps {
  centered?: boolean;
  children: string | React.ReactNode;
  className?: string;
  subtitle?: string;
  introText?: string;
}

const SectionTitle: FC<SectionTitleProps> = ({
  centered = false,
  children,
  className,
  subtitle,
  introText,
}) => (
  <Wrapper className={className} $centered={centered}>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <Heading variant={HeadingVariant.BRAVO}>{children}</Heading>
    {introText && <IntroText>{introText}</IntroText>}
  </Wrapper>
);

export default SectionTitle;
