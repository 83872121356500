import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from 'src/components/Layout';
import Content from 'src/components/Content';
import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import SEO from 'components/SEO';
import Toggle, { ToggleVariant } from 'components/Toggle';
import ComparisonTable, { Feature, Provider } from 'components/ComparisonTable';
import SectionTitle from 'components/SectionTitle';

const CompetitorsPage: FC = () => {
  const { t } = useTranslation();

  const supplierIntegration: Feature[] = [
    {
      title: t('competitors_section_1_row_1_title'),
      description: t('competitors_section_1_row_1_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_1_row_2_title'),
      description: t('competitors_section_1_row_2_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_1_row_3_title'),
      description: t('competitors_section_1_row_3_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_1_row_4_title'),
      description: t('competitors_section_1_row_4_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_1_row_5_title'),
      description: t('competitors_section_1_row_5_text'),
      providers: {
        OmniGet: 'As an additional service',
        Wise2sync: 'As an additional service',
        Baselinker: 'As an additional service',
      },
    },
  ];

  const supplierIntegrationFeatures: Feature[] = [
    {
      title: t('competitors_section_1_row_6_title'),
      description: t('competitors_section_1_row_6_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_1_row_7_title'),
      description: t('competitors_section_1_row_7_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_1_row_8_title'),
      description: t('competitors_section_1_row_8_text'),
      providers: {
        OmniGet: false,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_1_row_9_title'),
      description: t('competitors_section_1_row_9_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
  ];

  const PIM: Feature[] = [
    {
      title: t('competitors_section_2_row_1_title'),
      description: t('competitors_section_2_row_1_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_2_row_2_title'),
      description: t('competitors_section_2_row_2_text'),
      providers: {
        OmniGet: 'As an additional service',
        Wise2sync: 'As an additional service',
        Baselinker: 'As an additional service',
      },
    },
  ];

  const PIMFeatures: Feature[] = [
    {
      title: t('competitors_section_2_row_3_title'),
      description: t('competitors_section_2_row_3_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_2_row_4_title'),
      description: t('competitors_section_2_row_4_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_2_row_5_title'),
      description: t('competitors_section_2_row_5_text'),
      providers: {
        OmniGet: false,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_2_row_6_title'),
      description: t('competitors_section_2_row_6_text'),
      providers: {
        OmniGet: true,
        Wise2sync: true,
        Baselinker: true,
      },
    },
    {
      title: t('competitors_section_2_row_7_title'),
      description: t('competitors_section_2_row_7_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
    {
      title: t('competitors_section_2_row_8_title'),
      description: t('competitors_section_2_row_8_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
  ];

  const marketing: Feature[] = [
    {
      title: t('competitors_section_3_row_1_title'),
      description: t('competitors_section_3_row_1_text'),
      providers: {
        OmniGet: true,
        Wise2sync: false,
        Baselinker: false,
      },
    },
  ];

  const providers: Provider[] = [
    { name: 'OmniGet' },
    { name: 'Wise2sync' },
    { name: 'Baselinker' },
  ];

  return (
    <Layout>
      <Content>
        <SectionTitle>
          <Trans i18nKey="competitors_page_title" />
        </SectionTitle>
        <Toggle
          variant={ToggleVariant.BRAVO}
          content={[
            {
              title: t('competitors_section_1_title'),
              content: (
                <HeadingScope>
                  <ComparisonTable
                    features={supplierIntegration}
                    providers={providers}
                  />
                  <Heading variant={HeadingVariant.DELTA}>
                    <Trans i18nKey="common_features" />
                  </Heading>
                  <ComparisonTable
                    features={supplierIntegrationFeatures}
                    providers={providers}
                  />
                </HeadingScope>
              ),
            },
            {
              title: t('competitors_section_2_title'),
              content: (
                <HeadingScope>
                  <ComparisonTable features={PIM} providers={providers} />
                  <Heading variant={HeadingVariant.DELTA}>
                    <Trans i18nKey="common_features" />
                  </Heading>
                  <ComparisonTable
                    features={PIMFeatures}
                    providers={providers}
                  />
                </HeadingScope>
              ),
            },
            {
              title: t('competitors_section_3_title'),
              content: (
                <ComparisonTable features={marketing} providers={providers} />
              ),
            },
          ]}
        />
      </Content>
    </Layout>
  );
};

export default CompetitorsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="OmniGet vs Competitors | OmniGet"
    description="Compare OmniGet with leading competitors in product information management. Discover why OmniGet stands out with its powerful features and intuitive interface."
  />
);
