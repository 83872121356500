import React, { FC } from 'react';
import styled from 'styled-components';

import { CheckmarkIcon, CrossIcon } from 'src/assets/icons';
import ExpandableText from 'components/ExpandableText';
import { colors } from 'src/theme/colors';
import { ComparisonTableProps } from './types';
import { device } from 'src/theme/breakpoints';

// Main styles:

const Table = styled('table')`
  border-collapse: collapse;
  margin-bottom: 64px;
  overflow-x: auto;
  width: 100%;

  @media ${device.mobileDOWN} {
    display: none;
  }
`;

const Head = styled('th')`
  font-size: 12px;

  @media ${device.mobileDOWN} {
    font-weight: 400;
    padding: 12px 8px;
  }

  @media ${device.tabletUP} {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 4px;
  }
`;

const Row = styled('tr')`
  border-bottom: 1px solid ${colors.grey200};
  border-top: 1px solid ${colors.grey200};
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 13px;

  & path {
    stroke-width: 3;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  width: 13px;
`;

const ProviderCell = styled('td')`
  font-size: 12px;
  padding: 12px 8px;
  text-align: center;

  @media ${device.tabletUP} {
    font-size: 14px;
    padding: 18px 4px;
    width: 12%;
  }
`;

// Mobile styles:

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 16px;

  @media ${device.tabletUP} {
    display: none;
  }
`;

const ComparisonTable: FC<ComparisonTableProps> = ({ features, providers }) => (
  <>
    {features.map((feature, featureIndex) => (
      <Container key={featureIndex}>
        <ExpandableText title={feature.title}>
          {feature.description}
        </ExpandableText>
        <table>
          <thead>
            <tr>
              {providers.map(provider => (
                <Head key={provider.name}>{provider.name}</Head>
              ))}
            </tr>
          </thead>
          <tbody>
            <Row>
              {providers.map(provider => (
                <ProviderCell key={provider.name}>
                  {typeof feature.providers[provider.name] === 'boolean' && (
                    <>
                      {feature.providers[provider.name] ? (
                        <StyledCheckmarkIcon />
                      ) : (
                        <StyledCrossIcon />
                      )}
                    </>
                  )}
                  {typeof feature.providers[provider.name] === 'string' && (
                    <>{feature.providers[provider.name]}</>
                  )}
                </ProviderCell>
              ))}
            </Row>
          </tbody>
        </table>
      </Container>
    ))}
    <Table>
      <thead>
        <tr>
          <th></th>
          {providers.map(provider => (
            <Head key={provider.name}>{provider.name}</Head>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map((feature, featureIndex) => (
          <Row key={featureIndex}>
            <td>
              <ExpandableText title={feature.title}>
                {feature.description}
              </ExpandableText>
            </td>
            {providers.map(provider => (
              <ProviderCell key={provider.name}>
                {typeof feature.providers[provider.name] === 'boolean' && (
                  <>
                    {feature.providers[provider.name] ? (
                      <StyledCheckmarkIcon />
                    ) : (
                      <StyledCrossIcon />
                    )}
                  </>
                )}
                {typeof feature.providers[provider.name] === 'string' && (
                  <>{feature.providers[provider.name]}</>
                )}
              </ProviderCell>
            ))}
          </Row>
        ))}
      </tbody>
    </Table>
  </>
);

export default ComparisonTable;
