import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';
import { ToggleVariant } from './constants';

const Buttons = styled.div<{ $variant: ToggleVariant }>`
  ${({ $variant }) =>
    $variant === ToggleVariant.ALFA &&
    css`
      background-color: ${colors.green300};
      border-radius: 32px;
      height: 48px;
      margin: 0 auto;
      padding: 4px;
      width: max-content;
    `}

  ${({ $variant }) =>
    $variant === ToggleVariant.BRAVO &&
    css`
      column-gap: 38px;
      display: flex;
      margin: 0 auto;

      @media ${device.mobileDOWN} {
        align-items: flex-start;
        flex-direction: column;
      }
    `}
`;

const Button = styled.button<{
  $variant: ToggleVariant;
  $active: boolean;
  $isOnDark: boolean;
}>`
  ${({ $variant, $active }) =>
    $variant === ToggleVariant.ALFA &&
    css`
      background-color: ${$active ? `${colors.white}` : 'transparent'};
      border: none;
      border-radius: 34px;
      color: ${$active ? `${colors.black}` : `${colors.white}`};
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      height: 100%;
      padding: 0 26px;
      width: max-content;

      @media ${device.mobileDOWN} {
        padding: 0 16px;
      }
    `}

  ${({ $variant, $active, $isOnDark }) =>
    $variant === ToggleVariant.BRAVO &&
    css`
      background-color: transparent;
      border: none;
      color: ${$active ? `${colors.black}` : `${colors.grey600}`};
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      padding: 0;

      ${$active &&
      css`
        position: relative;

        &:after {
          background-color: ${colors.grey600};
          bottom: -8px;
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      `}

      ${$isOnDark &&
      css`
        color: ${$active ? `${colors.grey200}` : `${colors.grey600}`};

        &:after {
          background-color: ${colors.grey200};
        }
      `}

      @media ${device.mobileDOWN} {
        margin: 16px 0;
      }
    `}
`;

const Content = styled.div<{ $active: boolean }>`
  display: ${({ $active }) => ($active ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: 48px;

  @media ${device.mobileDOWN} {
    padding-top: 24px;
  }
`;

interface ToggleContent {
  title: string;
  content: React.ReactNode;
}

interface ToggleProps {
  variant: ToggleVariant;
  content: ToggleContent[];
  isOnDark?: boolean;
}

const Toggle: FC<ToggleProps> = ({ variant, content, isOnDark = false }) => {
  const [activeToggle, setActiveToggle] = useState<number>(0);

  return (
    <>
      <Buttons $variant={variant} role="tablist">
        {content.map((item, index) => (
          <Button
            key={`toggle-button-${index}`}
            onClick={() => setActiveToggle(index)}
            $active={activeToggle === index}
            $variant={variant}
            role="tab"
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            aria-selected={activeToggle === index}
            $isOnDark={isOnDark}
          >
            {item.title}
          </Button>
        ))}
      </Buttons>
      {content.map((item, index) => (
        <Content
          key={`toggle-content-${index}`}
          $active={activeToggle === index}
          role="tabpanel"
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {item.content}
        </Content>
      ))}
    </>
  );
};

export default Toggle;
